














































/**
 * GalileoAddressPicker Component
 *
 * Provides a user interface for entering and selecting billing addresses.
 * Includes address search, country selection, and manual input.
 *
 * @prop {boolean} [isNewCard=true] - Indicates whether the address is for a new card.
 *
 * @emits {isValid} - Emitted when the address is validated.  Payload is a boolean indicating validity.
 */

import { AppInputCountry } from '@oen.web.vue2/ui'

import AddressFieldsSwitcher from '@galileo/components/Views/PaymentMethod/BillingAddressCases/AddressFieldsSwitcher.vue'

import XeAddressSearch from '@galileo/components/XeAddressSearch/XeAddressSearch.vue'

import {
  PropType,
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  reactive,
  watch,
} from '@vue/composition-api'
import AddressModel, {
  AddressFieldsValidation,
  addressFields,
} from '@galileo/models/Address/BillingAddresses/AddressModel'
import {
  useCardAccountStore,
  useCountriesStore,
  useI18nStore,
  usePaymentsStore,
  useResourcesStore,
} from '@galileo/stores'
import Country from '@galileo/models/Country/app'
import { CardSettings } from '@galileo/models/CardAccount'
import { usePromiseLazy } from 'vue-composable'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'
import { translit } from '@galileo/utilities/nonlatin-latin-convertor.utility'

export default defineComponent({
  components: {
    AppInputCountry,
    AddressFieldsSwitcher,
    XeAddressSearch,
  },
  props: {
    isNewCard: {
      type: Boolean as PropType<boolean>,
      default: true,
      required: false,
    },
    selectedCardAddress: {
      type: Object as PropType<any>,
      default: null,
      required: false,
    },
    value: {
      type: Object as PropType<AddressModel>,
      required: true,
      default: () => new AddressModel(),
    },
  },
  setup(props, { emit }) {
    const userAddressModel = ref<AddressModel>(new AddressModel())

    const isAddressValid = ref<boolean>(false)
    const showValidationErrorMessage = ref(false)

    watch(props.value, (newVal) => {
      updateModel(newVal)
    })

    const line1Valid = ref(true)

    const validateAddress = (isValid: boolean) => {
      isAddressValid.value = isValid
      submit(isValid)
    }

    const updateModel = (value: AddressModel) => {
      line1Valid.value = true
      const line1Validation = fieldValidations.value.find((field) => field.fieldName === 'line1')

      if (line1Validation && !manualSelectionEnabled.value) {
        const fieldRegex = new RegExp(line1Validation.regex)
        const sanitaziedLine1 = removeDiacritics(translit(value.line1))
        line1Valid.value = fieldRegex.test(sanitaziedLine1)
      }

      if (!line1Valid.value) {
        showValidationErrorMessage.value = true
      } else {
        showValidationErrorMessage.value = false
      }

      if (isAddressValid.value && line1Valid.value) {
        submit(true)
        emit('input', value)
      } else {
        submit(false)
      }
    }
    const cardAccountStore = useCardAccountStore()
    const paymentsStore = usePaymentsStore()
    const countriesStore = useCountriesStore()
    const resourcesStore = useResourcesStore()

    const isInputVisible = ref<boolean>(true)
    const countries = ref<any>()
    const filteredCountries = ref<Country>()

    const cardSettings = ref<Nullable<CardSettings>>(null)
    const billingAddress = ref<any>(null)

    const { $t } = useI18nStore()

    const fieldValidations = ref<AddressFieldsValidation[]>([])

    /**
     *
     * @param {boolean} isValid - Indicates if the address is valid.
     */

    const submit = (addressValid: boolean): void => {
      emit('onSave', addressValid)
    }

    const { value: paymentAccount } = computed(() => paymentsStore.selectedPaymentMethod)

    onBeforeMount(async () => {
      if (props.selectedCardAddress !== null) {
        billingAddress.value = {
          billingCountry: props.selectedCardAddress.country,
          billingAddress: props.selectedCardAddress.street,
          billingAddress2: props.selectedCardAddress.city,
          billingCity: props.selectedCardAddress.city,
          billingPostalCode: props.selectedCardAddress.postalCode,
          billingState: props.selectedCardAddress.state,
        }
      } else {
        billingAddress.value = {
          billingCountry: paymentAccount.billingCountry,
          billingAddress: paymentAccount.billingStreet,
          billingAddress2: paymentAccount.billingStreetLine2,
          billingCity: paymentAccount.billingCity,
          billingPostalCode: paymentAccount.billingPostalCode,
          billingState: paymentAccount.billingState,
        }
      }
      mapFromBillingAddress(billingAddress.value.billingCountry)
      // addressFoundAndSelected.value = true
      if (!props.isNewCard) {
        addressFoundAndSelected.value = true
      }
      await refreshCardSettings(billingAddress.value.billingCountry)
    })

    const mapFromBillingAddress = (country: Nullable<string> = null) => {
      if (!country) {
        country = billingAddress.value.billingCountry
      } else {
        //userAddressModel.value = new AddressModel()
        userAddressModel.value.countryCode = country
        return
      }

      userAddressModel.value = new AddressModel(
        billingAddress.value.billingAddress,
        billingAddress.value.billingAddress2,
        '',
        billingAddress.value.billingCity,
        billingAddress.value.billingState,
        billingAddress.value.billingPostalCode,
        country ?? ''
      )
    }

    const fieldValidationMapping: { [key: string]: addressFields } = {
      BillToAddressLine1: 'line1',
      BillToAddressLine2: 'line2',
      BillToAddressCity: 'place',
      BillToAddressState: 'state',
      BillToAddressPostalCode: 'postCode',
    }

    const refreshCardSettings = async (country: Nullable<string> = null) => {
      cardSettings.value = await cardAccountStore.fetchCardSettings(
        country,
        paymentAccount?.currency
      )

      if (!cardSettings.value) {
        //early return
        return
      }

      countries.value = countriesStore.getCountries

      if (cardSettings.value.cardBillingCountries) {
        filteredCountries.value = countries.value.filter((item: Country) => {
          return cardSettings.value?.cardBillingCountries.some((c) => {
            return c === item.value
          })
        })
      } else {
        filteredCountries.value = countries.value.filter(
          (item: Country) => item.value == props.value.countryCode
        )
      }

      if (cardSettings.value.cardFields) {
        fieldValidations.value = []
        cardSettings.value.cardFields.forEach((field: any) => {
          if (field.name) {
            const mappedFieldName = fieldValidationMapping[field.name]
            if (mappedFieldName) {
              // Extract the regex from the field definition (assuming it's called 'regEx')
              let regex = field.regEx || '' // Provide a default empty string if no regex

              // we're updating the regex for line 1 to contian special characters
              //those will be removed when we are sending the data to the backend
              if (field.name === 'BillToAddressLine1') {
                const additionalChars = '#()º'
                regex = modifyRegex(regex, additionalChars)
              }
              const validation = new AddressFieldsValidation(
                mappedFieldName as addressFields,
                regex
              )
              fieldValidations.value.push(validation)
            }
          }
        })
      }
    }

    //add additionalChars to existing regex
    const modifyRegex = (regex: string, additionalChars: string) => {
      let newRegex = regex
      const regexContent = regex.match(/\[(.*?)\]/)
      if (regexContent) {
        const newContent = `${regexContent[1]}${additionalChars}`
        //replace the content between [] with the new content
        newRegex = regex.replace(regexContent[1], newContent)
      }
      //we change also the max allowed chars to 255
      const maxLength = regex.match(/\{(\d+),(\d+)\}/)
      if (maxLength) {
        newRegex = newRegex.replace(maxLength[2], '255')
      }
      return newRegex
    }

    const getAddressDetailsPromiseLazy = reactive(
      usePromiseLazy((selectedId) => {
        let result = resourcesStore.getAddressDetails(selectedId)
        return result
      })
    )

    const searchPromiseLazy = reactive(
      usePromiseLazy(({ query, selectedId = null }) => {
        props.value.line1 = query
        let result = resourcesStore.getAddressSearchResult({
          searchTerm: query,
          searchContext: selectedId,
          country: props.value.countryCode,
        })
        return result
      })
    )

    const addressFoundAndSelected = ref<boolean>(false)

    const manualSelectionEnabled = ref<boolean>(false)

    /**
     * Handles manual address selection (user wants to type in the address).
     */
    const onManualAddressSelect = () => {
      resetAddress()
      manualSelectionEnabled.value = true
      emit('manual')
    }

    /**
     * Handles address selection from the search results.
     *
     * @param {object} address - The selected address object.
     */
    const onSelectAddress = (address: any) => {
      emit('select')

      resetAddress()
      manualSelectionEnabled.value = false
      addressFoundAndSelected.value = true
      //const state = props.shouldUseProvinceIsoCode ? address.provinceCode : address.provinceName

      props.value.line1 = removeDiacritics(address.addressLine1)
      props.value.place = removeDiacritics(address.city)
      props.value.postCode = removeDiacritics(address.postCode)
      props.value.state = removeDiacritics(address.provinceName)
    }

    /**
     * Handles country selection changes.
     *
     * @param {object} country - The selected country object.
     */
    const countryChanged = async (country: { value: string }) => {
      isAddressValid.value = false
      props.value.countryCode = country.value
      resetAddress()
      await refreshCardSettings(props.value.countryCode)
    }

    const resetAddress = () => {
      manualSelectionEnabled.value = false
      addressFoundAndSelected.value = false
      props.value.line1 = ''
      props.value.line2 = ''
      props.value.line3 = ''
      props.value.place = ''
      props.value.postCode = ''
      props.value.state = ''
    }

    return {
      $t,
      submit,
      filteredCountries,
      isInputVisible,
      getAddressDetailsPromiseLazy,
      searchPromiseLazy,
      onManualAddressSelect,
      onSelectAddress,
      addressFoundAndSelected,
      manualSelectionEnabled,
      countryChanged,
      fieldValidations,
      line1Valid,
      validateAddress,
      showValidationErrorMessage,
    }
  },
})
