












































import { defineComponent, toRef, ref, watch, onBeforeMount, PropType } from '@vue/composition-api'

import AddressModel, {
  AddressFieldsValidation,
} from '@galileo/models/Address/BillingAddresses/AddressModel'

import { useValidation } from 'vue-composable'

import { AppInputText, AppInputDropdown } from '@oen.web.vue2/ui'

import { useI18nStore } from '@galileo/stores'

import getValueRequiredValidation from '@galileo/utilities/validations.utility'

import { translit } from '@galileo/utilities/nonlatin-latin-convertor.utility'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'

export default defineComponent({
  components: { AppInputText, AppInputDropdown },
  props: {
    value: {
      type: Object as PropType<AddressModel>,
      default: () => new AddressModel(),
    },
    country: {
      type: String as PropType<string>,
      default: 'NZ',
    },
    fieldsValidation: {
      type: [] as PropType<AddressFieldsValidation[]>,
      required: false,
    },
    shouldUseProvinceIsoCode: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    shouldDisplayAddressLine: {
      type: Boolean as PropType<boolean>,
      default: true,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const valueRequired = getValueRequiredValidation()
    const otherFieldsVisible = ref(false)

    //#region validation
    let formValidation = ref(
      useValidation({
        line1: {
          $value: toRef(props.value, 'line1'),
          valueRequired,
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))

              const field = props.fieldsValidation?.find((field) => field.fieldName === 'line1')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          },
        },
        place: {
          $value: toRef(props.value, 'place'),
          valueRequired,
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))

              const field = props.fieldsValidation?.find((field) => field.fieldName === 'place')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          },
        },
        state: {
          $value: toRef(props.value, 'state'),
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))

              const field = props.fieldsValidation?.find((field) => field.fieldName === 'state')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          },
          // required,
        },
        postCode: {
          $value: toRef(props.value, 'postCode'),
          valueRequired,
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))

              const field = props.fieldsValidation?.find((field) => field.fieldName === 'postCode')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          },
        },
        line2: {
          $value: toRef(props.value, 'line2'),
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))
              const field = props.fieldsValidation?.find((field) => field.fieldName === 'line2')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          },
        },
      })
    )

    const showPostalCodeValidation = ref(false)

    const onPostalCodeFocus = () => {
      showPostalCodeValidation.value = false
    }

    const onPostalCodeFocusOut = () => {
      showPostalCodeValidation.value = true
      formValidation.value.postCode.$touch()
    }

    watch(formValidation.value, (validation) => {
      emit('isValid', !validation.$anyInvalid)
    })

    onBeforeMount(() => {
      if (props.value) {
        emit('isValid', !formValidation.value.$anyInvalid)
      }
    })
    //#endregion

    return {
      $t,
      formValidation,
      onPostalCodeFocus,
      onPostalCodeFocusOut,
      otherFieldsVisible,
    }
  },
})
