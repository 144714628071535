



















































































import { defineComponent, PropType } from '@vue/composition-api'
import AddressModel, {
  AddressFieldsValidation,
} from '@galileo/models/Address/BillingAddresses/AddressModel'

import USAddressForm from '@galileo/components/Views/PaymentMethod/BillingAddressCases/USAddressForm.vue'
import AUAddressForm from '@galileo/components/Views/PaymentMethod/BillingAddressCases/AUAddressForm.vue'
import NZAddressForm from '@galileo/components/Views/PaymentMethod/BillingAddressCases/NZAddressForm.vue'
import UKAddressForm from '@galileo/components/Views/PaymentMethod/BillingAddressCases/UKAddressForm.vue'
import CAAddressForm from '@galileo/components/Views/PaymentMethod/BillingAddressCases/CAAddressForm.vue'
import EUAddressForm from '@galileo/components/Views/PaymentMethod/BillingAddressCases/EUAddressForm.vue'
import ROAddressForm from '@galileo/components/Views/PaymentMethod/BillingAddressCases/ROAddressForm.vue'

export default defineComponent({
  components: {
    USAddressForm,
    AUAddressForm,
    NZAddressForm,
    UKAddressForm,
    CAAddressForm,
    EUAddressForm,
    ROAddressForm,
  },
  props: {
    value: {
      type: Object as PropType<AddressModel>,
      required: true,
      default: () => new AddressModel(),
    },
    country: {
      type: String as PropType<string>,
      required: true,
      default: 'USA',
    },
    cardFields: {
      type: [],
      required: false,
    },
    fieldsValidation: {
      type: [] as PropType<AddressFieldsValidation[]>,
      required: false,
    },
    shouldUseProvinceIsoCode: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    shouldDisplayAddressLine: {
      type: Boolean as PropType<boolean>,
      default: true,
      required: false,
    },
  },
  setup(_props, { emit }) {
    const updateModel = (value: AddressModel) => {
      emit('input', value)
    }

    const isValidHandler = (value: boolean) => {
      emit('isValid', value)
    }

    const clearAddress = () => {
      emit('clearAddress')
    }

    return { updateModel, isValidHandler, clearAddress }
  },
})
