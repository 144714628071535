

































































import { defineComponent, ref, onBeforeMount, watch, toRef, PropType } from '@vue/composition-api'

import AddressModel, {
  AddressFieldsValidation,
} from '@galileo/models/Address/BillingAddresses/AddressModel'

import { useValidation } from 'vue-composable'

import { AppInputText, AppInputDropdown } from '@oen.web.vue2/ui'

import { useI18nStore, useCountriesStore } from '@galileo/stores'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'
import State from '@galileo/models/State/app'

import { translit } from '@galileo/utilities/nonlatin-latin-convertor.utility'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'

export default defineComponent({
  components: { AppInputText, AppInputDropdown },
  props: {
    value: {
      type: Object as PropType<AddressModel>,
      default: () => new AddressModel(),
    },
    country: {
      type: String as PropType<string>,
      default: 'US',
    },
    fieldsValidation: {
      type: [] as PropType<AddressFieldsValidation[]>,
      required: false,
    },
    shouldUseProvinceIsoCode: {
      type: Boolean as PropType<boolean>,
      default: false,
      required: false,
    },
    shouldDisplayAddressLine: {
      type: Boolean as PropType<boolean>,
      default: true,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const countriesStore = useCountriesStore()
    const valueRequired = getValueRequiredValidation()
    const otherFieldsVisible = ref(false)

    const states = ref<State[]>([])

    const showPostalCodeValidation = ref(false)

    //#region validation
    let formValidation = ref(
      useValidation({
        line1: {
          $value: toRef(props.value, 'line1'),
          valueRequired,
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))
              const field = props.fieldsValidation?.find((field) => field.fieldName === 'line1')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          },
        },
        place: {
          $value: toRef(props.value, 'place'),
          valueRequired,
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))

              const field = props.fieldsValidation?.find((field) => field.fieldName === 'place')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          },
        },
        state: {
          $value: toRef(props.value, 'state'),
          valueRequired,
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))

              const field = props.fieldsValidation?.find((field) => field.fieldName === 'state')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          }
        },
        postCode: {
          $value: toRef(props.value, 'postCode'),
          valueRequired,
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))

              const field = props.fieldsValidation?.find((field) => field.fieldName === 'postCode')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          },
        },
        line2: {
          $value: toRef(props.value, 'line2'),
          isValid: {
            $validator(v: string) {
              const sanitised = removeDiacritics(translit(v))
              const field = props.fieldsValidation?.find((field) => field.fieldName === 'line2')
              if (field) {
                const fieldRegex = new RegExp(field.regex)
                return fieldRegex.test(sanitised)
              }
              return true
            },
            $message: $t('UseFieldValidation.FieldIsNotValidError').value,
          },
        },
      })
    )

    const onPostalCodeFocus = () => {
      showPostalCodeValidation.value = false
    }

    const onPostalCodeFocusOut = () => {
      showPostalCodeValidation.value = true
      formValidation.value.postCode.$touch()
    }

    watch(formValidation.value, (validation) => {

      emit('isValid', !validation.$anyInvalid)
    })
    //#endregion

    onBeforeMount(async () => {
      if (props.value.line1?.indexOf(',')) {
        const filteredAddress = props.value.line1.split(',')
        props.value.line1 = filteredAddress[0]
        props.value.line2 = filteredAddress[1]
      }

      if (props.value) {
        emit('isValid', !formValidation.value.$anyInvalid)
      }
      states.value = await countriesStore.getStatesByCountryCode('US')
    })

    return {
      states,
      $t,
      formValidation,
      onPostalCodeFocus,
      onPostalCodeFocusOut,
      otherFieldsVisible,
    }
  },
})
