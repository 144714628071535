import { render, staticRenderFns } from "./CAAddressForm.vue?vue&type=template&id=596d4832&"
import script from "./CAAddressForm.vue?vue&type=script&lang=ts&"
export * from "./CAAddressForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.9.2_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__cache-loader@4.1.0_webp_dbc1f76d1931dbdfddfdff69f826e4c8/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports